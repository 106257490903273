import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { fetchProject, updateJusOnProjet } from "../../actions/ProjetActions";
import { useParams } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Card,
    CardContent,
    Grid,
    Box,
    List,
    ListItem,
    ListItemText,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    ListItemIcon,
    Button,
    TableRow,
} from "@mui/material";
import Spinner from '../Spinner/Index';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import { useMaterialUIController } from "../../context";
import AssignTache from "./AssignProject";
import ProjectTasksTable from "../taches/ProjectTasksTable";
import FileUploadComponent from "./UploadFiles";
import ProjectFilesList from "./ProjectFilesList";
import AccessDenied from "../Errors/AccessDenied";
import ProjectAdvancesList from './ProjectAdvances';
import AddAdvance from "./AddAdvance";
import CheckIcon from "@mui/icons-material/Check";
import AddTaskIcon from '@mui/icons-material/AddTask';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import PolygonServices from '../../services/polygonServices';
import { decodeId, encodeId } from "../../actions/Crypte";
import UploadFiles from "./UploadFile";
import CreateTache from "../taches/CreateTask";
import ProjectTasks from "../taches/ProjectTasks";
const ProjectProgressLine = ({ status }) => {
    const statusOrder = ['preparation', 'terrain', 'bureau', 'controle', 'livraison', 'cloture'];
    const calculateColor = (percentage) => {
        const hue = (percentage / 100) * 120;
        return `hsl(${hue}, 100%, 50%)`;
    };

    const percentage = (statusOrder.indexOf(status) / (statusOrder.length - 1)) * 100;
    let percentageToShow = percentage;
    if (status === 'preparation') {
        percentageToShow = 5;
    }
    const color = calculateColor(percentage);
    let marl;
    let marr;
    if (status !== 'cloture') {
        marl = 10;
        marr = 0;
    } else {
        marl = 0;
        marr = 14;
    }
    return (
        <div className="mt-5 mb-5">
            <div style={{ position: 'relative', textAlign: 'center' }}>
                <div className="progress" style={{ borderRadius: '10px', overflow: 'hidden' }}>
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow={percentageToShow}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${percentageToShow}%`, backgroundColor: color, borderRadius: '8px' }}
                    ></div>
                </div>
                <div style={{ position: 'absolute', top: '50%', left: `${percentage}%`, transform: 'translate(-50%, -50%)' }}>
                    <Box>
                        <Typography variant="subtitle1" style={{ marginLeft: marl, marginRight: marr }}>
                            {status}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: '8px', marginLeft: marl, marginRight: marr }}>
                            {`${percentage}%`}
                        </Typography>
                    </Box>
                </div>
            </div>
        </div>
    );
};

const ProjectInfo = ({ currentUser, project, fetchProject }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { id } = useParams();
    const projectId = decodeId(id);
    const dispatch = useDispatch();

    const [assignedProject, setAssignedProject] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isAdvancesDialogOpen, setIsAdvancesDialogOpen] = useState(false);
    const [isTasksDialogOpen, setIsTasksDialogOpen] = useState(false);
    const [isPiecesDialogOpen, setIsPiecesDialogOpen] = useState(false);
    const [isFilesDialogOpen, setIsFilesDialogOpen] = useState(false);
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [isAssignedProjectOpen, setIsAssignedProjectOpen] = useState(false);
    const [isClotureDialogOpen, setIsClotureDialogOpen] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('');
    const [color, setColor] = useState('');

    // useEffect(() => {
    //     fetchPolygons();
    // }, [id]);


    // const fetchPolygons = async () => {
    //     try {
    //         const response = await PolygonServices.getPolygonsByProjectId(projectId);
    //         setPolygons(response.data.polygons);
    //     } catch (error) {
    //         console.error('Error fetching polygons:', error);
    //     }
    // };

    useEffect(() => {
        if (!project || project.id !== Number(projectId)) {
            fetchProject(projectId);
        }
        if (project && project.statut) {
            if (project.statut === "cloture") {
                setAssignedProject(false);
            } else {
                setAssignedProject(true);
            }
        }
    }, [projectId, project, fetchProject]);


    if (!project) {
        return <div> <Spinner /> </div>;
    }

    const openDialog = (project) => {
        setSelectedItem(project);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const handleCloture = (e) => {
        e.preventDefault();
        dispatch(updateJusOnProjet(project.id, { statut: 'cloture', date_cloture: new Date() }))
            .then(() => {
                setIsClotureDialogOpen(false);
                // setSubmited(true);
                // setMessageSuccess('Devis confirmé avec succès')
                // setColor('success');
            })
            .catch((err) => {
                console.log(err);
                // setSubmited(true);
                // setMessageSuccess('Error de confirmation de Devis')
                // setColor('error');
            })
    }

    const openAdvancesDialog = (project) => {
        setSelectedItem(project);
        setIsAdvancesDialogOpen(true);
    };

    const closeAdvancesDialog = () => {
        setSelectedItem(null);
        setIsAdvancesDialogOpen(false);
    };

    const openTasksDialog = (project) => {
        setSelectedItem(project);
        setIsTasksDialogOpen(true);
    };

    const closeTasksDialog = () => {
        setSelectedItem(null);
        setIsTasksDialogOpen(false);
    };

    const openPiecesDialog = (project) => {
        setSelectedItem(project);
        setIsPiecesDialogOpen(true);
    };

    const closePiecesDialog = () => {
        setSelectedItem(null);
        setIsPiecesDialogOpen(false);
    };

    const renderTableRow = (label, value) => (
        <TableRow>
            <TableCell>
                <strong>{label}:</strong>
            </TableCell>
            <TableCell>{value || "-"}</TableCell>
        </TableRow>
    );
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography mt={'10px'} variant="h2" textAlign="center" gutterBottom>
                                        Projet: {project.numero}
                                    </Typography>
                                    <Divider style={{ margin: "8px auto", width: "50%", height: "4px", backgroundColor: "rgba(18, 104, 255, 1)" }} />
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexWrap="wrap"
                                        mb={2}
                                        sx={{
                                            "& .MuiButton-root": {
                                                margin: "8px",
                                                minWidth: "150px",
                                            },
                                        }}
                                    >
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les projets")) && (
                                            <MDButton variant="contained" className="me-1" component={Link} to={`/projects/update/${encodeId(project.id)}`} color="info">
                                                <BorderColorIcon />&nbsp;Modifier
                                            </MDButton>
                                        )}
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Affecter les tâches")) && (
                                            <MDButton variant="contained" className="me-1" onClick={() => setIsAssignedProjectOpen(true)} color="info">
                                                <AddTaskIcon />&nbsp; Attribuer une tâche
                                            </MDButton>
                                        )}
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des encaissements")) && (
                                            <MDButton variant="contained" className="me-1" onClick={() => openDialog(project)} color="info">
                                                <PriceCheckIcon />&nbsp;Ajouter une avance
                                            </MDButton>
                                        )}
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Importer les fichiers")) && (
                                            <MDButton variant="contained" className="me-1" onClick={() => setIsUploadDialogOpen(true)} color="info">
                                                <CloudUploadIcon />&nbsp;Importer Fichiers
                                            </MDButton>
                                        )}
                                        <MDButton variant="contained" className="me-1" onClick={openPiecesDialog} color="info">
                                            <DocumentScannerIcon />&nbsp; liste des Pieces
                                        </MDButton>
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les tâches")) && (
                                            <MDButton variant="contained" className="me-1" onClick={openTasksDialog} color="info">
                                                <AssignmentIcon />&nbsp; Liste des tâches
                                            </MDButton>
                                        )}
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les encaissements")) && (
                                            <MDButton variant="contained" className="me-1" onClick={openAdvancesDialog} color="info">
                                                <CurrencyExchangeIcon />&nbsp;liste des avances
                                            </MDButton>
                                        )}
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers")) && (
                                            <MDButton variant="contained" className="me-1" onClick={() => setIsFilesDialogOpen(true)} color="info">
                                                <FileCopyIcon />&nbsp;liste des Fichiers
                                            </MDButton>
                                        )}
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les projets") || currentUser.permissions.includes("Clôturer les projets")) && (
                                            <>
                                                {(project?.statut !== 'cloture' && (project?.type === 'Petite Projet' || project?.type === null)) &&
                                                    <MDButton variant="contained" className="me-1" onClick={() => setIsClotureDialogOpen(true)} color="info">
                                                        <FileCopyIcon />&nbsp;Clôturer le projet
                                                    </MDButton>
                                                }
                                            </>
                                        )}
                                    </Box>
                                    <TableContainer className="shadow-lg p-3 bg-white rounded" style={{ marginTop: "20px" }}>
                                        <Table className="">
                                            <TableBody>
                                                {renderTableRow("Statut", <ProjectProgressLine status={project.statut} />)}
                                                {renderTableRow("Référence", project.reference_fonciere)}
                                                {currentUser && currentUser.roles.includes("ROLE_ADMIN") && renderTableRow("Id", project.id)}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && renderTableRow("Prix", project.prix)}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les contacts des projets")) && renderTableRow("Contact", project.projectContact?.name)}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && renderTableRow("Facturé", project.facture)}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && renderTableRow("Statut de paiement", project.paiement)}
                                                {renderTableRow("Date de Création", project.date_creation)}
                                                {renderTableRow("Situation", project.situation)}
                                                {renderTableRow("Client", project.client?.full_name)}
                                                {renderTableRow("Date de Clôture", project.date_cloture)}
                                                <TableRow>
                                                    <TableCell>
                                                        <strong>Nature:</strong>
                                                    </TableCell>
                                                    <TableCell>
                                                        {project?.nature && project?.nature.length > 0 ? (
                                                            <ul>
                                                                {project.nature.map((natures) => (
                                                                    <li key={natures.id}>{natures.name}</li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            "Aucune Nature!"
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                <>
                    <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                        Ajouter une Avance
                    </DialogTitle>
                    <DialogContent>
                        <AddAdvance currentUser={currentUser} numero={project?.numero} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
            <Dialog
                open={isAdvancesDialogOpen}
                onClose={closeAdvancesDialog}
                fullWidth
                maxWidth="md"
            >
                <>
                    <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                        Liste des avances
                    </DialogTitle>
                    <DialogContent>
                        <ProjectAdvancesList project={project} darkMode={darkMode} handleClose={closeAdvancesDialog} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeAdvancesDialog} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
            <Dialog
                open={isTasksDialogOpen}
                onClose={closeTasksDialog}
                fullWidth
                maxWidth="100%"

            >
                <>
                    <DialogTitle className='text-center' style={{ fontSize: '29px' }}>

                        {(project?.type === 'Petite Projet' || project?.type === null) ? (
                            <>
                                Table de processus
                            </>
                        ) : (
                            <>
                                Table des tâches
                            </>
                        )}
                    </DialogTitle>
                    <DialogContent>
                        {(project?.type === 'Petite Projet' || project?.type === null) ? (
                            <>
                                <ProjectTasksTable projectId={project?.id} darkMode={darkMode} currentUser={currentUser} />
                            </>
                        ) : (
                            <>
                                <ProjectTasks projectId={project?.id} darkMode={darkMode} currentUser={currentUser} />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeTasksDialog} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
            <Dialog
                open={isPiecesDialogOpen}
                onClose={closePiecesDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Pieces
                </DialogTitle>
                <DialogContent>
                    <List>
                        {project.pieces?.map((piece, index) => (
                            <React.Fragment key={piece.id}>
                                <ListItem
                                    sx={{
                                        padding: "8px",
                                        backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#ffffff",
                                        "&:hover": {
                                            backgroundColor: index % 2 === 0 ? "#e0e0e0" : "#f8f8f8",
                                            padding: "15px"
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText className={darkMode ? "text-light" : "text-dark"} primary={piece?.piece} />
                                </ListItem>
                                <Divider sx={{
                                    margin: '0px !important'
                                }} />
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePiecesDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isFilesDialogOpen}
                onClose={() => setIsFilesDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Fichiers
                </DialogTitle>
                <DialogContent>
                    <ProjectFilesList projectId={project?.id} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsFilesDialogOpen(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isUploadDialogOpen}
                onClose={() => setIsUploadDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Importation de fichiers
                </DialogTitle>
                <DialogContent>
                    <UploadFiles projectId={project?.id} handelClose={() => setIsUploadDialogOpen(false)} handelOpen={() => setIsFilesDialogOpen(true)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsUploadDialogOpen(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isAssignedProjectOpen}
                onClose={() => setIsAssignedProjectOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Attribuer une tâche
                </DialogTitle>
                <DialogContent>
                    {assignedProject ? (
                        <>
                            {project?.type === 'Petite Projet' ? (
                                <>
                                    <AssignTache projectId={project.id} />
                                </>
                            ) : (
                                <>
                                    <CreateTache projectId={project?.id} />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography variant="h5" textAlign="center" gutterBottom>
                                Le projet est déjà clôturé&nbsp;<SentimentSatisfiedAltIcon />
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAssignedProjectOpen(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isClotureDialogOpen}
                onClose={() => setIsClotureDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Clôturer le projet
                </DialogTitle>
                <DialogContent>
                    <Container>
                        <Typography variant="h6" textAlign={'center'} gutterBottom>
                            Es-tu sûr de vouloir clôturer ce projet?
                        </Typography>
                        <Box sx={{
                            textAlign: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button
                                onClick={handleCloture}
                                sx={{
                                    color: "#fff !important",
                                    backgroundColor: "green !important",
                                }}>
                                Oui
                            </Button>
                            <Button
                                onClick={() => setIsClotureDialogOpen(false)}
                                sx={{
                                    marginLeft: '0.6rem',
                                    color: "#fff !important",
                                    backgroundColor: "red !important",
                                }}>
                                Non
                            </Button>
                        </Box>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsClotureDialogOpen(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>

    );

};

const mapStateToProps = (state) => ({
    project: state.project.project,
});

const mapDispatchToProps = {
    fetchProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfo);

