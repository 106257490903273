import axios from 'axios';
import store from '../app/store';
import { logout } from '../actions/UserActions';

const AxiosInst = axios.create({
    // baseURL: "http://localhost:8000/api",
    // baseURL: "http://192.168.1.58:8000/api",
    // baseURL: "https://backend.metic.ma:8000/api",
    // baseURL: "https://data.saftuing.com:8001/api",
    baseURL: "https://backend.sm2e.ma:8015/api",
    // baseURL: "https://test.geomaping.net/api",
    // baseURL: "https://survback.syscamp.ma:8011/api",
    // baseURL: "https://serback.geosud.ma/api",
    // baseURL: "https://serback.surveyengineering.ma/api",
    
    headers: {
        "Content-type": "application/json",
        // "DASHBOARD_API_KEY": "Dashboard-Secret@KEY/:pas^^M",
    },
});

AxiosInst.interceptors.request.use(
    (config) => {
        const { auth } = store.getState();
        const { accessToken } = auth.user || {};

        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

AxiosInst.interceptors.request.use(
    (config) => {
        const { contact } = store.getState();
        const { accessToken } = contact.contact || {};

        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

AxiosInst.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;
        if (response && response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);

export default AxiosInst;


