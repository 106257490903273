import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, LayerGroup, Polyline as LeafletPolyline, useMap, LayersControl, Polygon as LeafletPolygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import 'proj4leaflet';
import PointServices from '../../services/pointServices';
import { Icon } from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Dialog, DialogTitle, DialogActions, Alert, Snackbar, TextField, Button, DialogContent, MenuItem } from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import PolylineServices from '../../services/polylineServices';
import {
    Paper,
    Grid,
    Typography,
    Box,
} from "@mui/material";
import L from 'leaflet';
import { MyLocation, AddLocationAlt, UploadFile, WrongLocation } from '@mui/icons-material';
import PolygonServices from '../../services/polygonServices';
import UpdatePoint from './UpdatePoint';
import AddPoint from './AddPoint';
import { Link } from 'react-router-dom';
import markerIconRedPng from 'leaflet/dist/images/icons8.gif';

const markerIcon = new Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

const redMarkerIcon = new Icon({
    iconUrl: markerIconRedPng,
    iconSize: [30, 41],
    iconAnchor: [17, 41],
});


const SurveyMap = ({ currentUser }) => {
    const [points, setPoints] = useState([]);
    const [convertedCoordinates, setConvertedCoordinates] = useState([]);
    const [otherPoints, setOtherPoints] = useState([]);
    const [convertedCoord, setConvertedCoord] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [selectedPointId, setSelectedPointId] = useState(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [convertedPolylines, setConvertedPolylines] = useState([]);
    const [convertedPolygons, setConvertedPolygons] = useState([]);
    const [initialZoom, setInitialZoom] = useState(10);
    const [polylines, setPolylines] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedPolyline, setSelectedPolyline] = useState(null);
    const [selectedPolygon, setSelectedPolygon] = useState(null);
    const [polygons, setPolygons] = useState([]);
    const [polylineData, setPolylineData] = useState([]);
    const [polygonData, setPolygonData] = useState([]);
    const [selectedProjection, setSelectedProjection] = useState('26192');
    const [inputX, setInputX] = useState('');
    const [inputY, setInputY] = useState('');
    const [popupPosition, setPopupPosition] = useState(null);
    const [popupContent, setPopupContent] = useState('');

    const mapRef = useRef(null);
    const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

    useEffect(() => {
        fetchPolylines();
        fetchPolygons();
    }, []);

    const fetchPolylines = async () => {
        try {
            const response = await PolylineServices.getPolylines();
            setPolylines(response.data.polylines);
        } catch (error) {
            console.error('Error fetching polylines:', error);
        }
    };

    const fetchPolygons = async () => {
        try {
            const response = await PolygonServices.getPolygons();
            setPolygons(response.data.polygons);
        } catch (error) {
            console.error('Error fetching polygons:', error);
        }
    };

    const handleDeletePolygon = async (polygonId) => {
        try {
            console.log(polygonId);
            await PolygonServices.deletePolygon(polygonId);
            handleSnackbarOpen('Polygon deleted successfully', 'success');
            fetchPolygons();
        } catch (error) {
            console.error('Error deleting polygon:', error);
            handleSnackbarOpen('Error deleting polygon', 'error');
        }
    };

    const handleDeletePolyline = async (polylineId) => {
        try {
            console.log(polylineId);
            await PolylineServices.deletePolyline(polylineId);
            handleSnackbarOpen('Polyline deleted successfully', 'success');
            fetchPolylines();
        } catch (error) {
            console.error('Error deleting Polyline:', error);
            handleSnackbarOpen('Error deleting Polyline', 'error');
        }
    };

    const fetchPoints = async () => {
        try {
            const response = await PointServices.fetchPointsByZone(selectedProjection);
            setPoints(response.data.points);
            console.log(selectedProjection);
        } catch (error) {
            console.error('Error fetching points:', error);
        }
    };

    const fetchOtherPoints = async () => {
        try {
            const response = await PointServices.fetchOtherPoints(selectedProjection);
            setOtherPoints(response.data.points);
            console.log(selectedProjection);
        } catch (error) {
            console.error('Error fetching points:', error);
        }
    };

    useEffect(() => {
        fetchPoints();
        fetchOtherPoints();
    }, [selectedProjection]);

    useEffect(() => {
        if (points?.length === 0) return;
        const sourceCRS = getSourceCRS(selectedProjection);

        proj4.defs('EPSG:3857', targetCRS);
        proj4.defs(`EPSG:${selectedProjection}`, sourceCRS);

        const converted = points?.map((point) => {
            const [x, y] = proj4(sourceCRS, targetCRS, point.geometry.coordinates);
            return [y, x];
        });

        setConvertedCoordinates(converted);
    }, [points, selectedProjection]);
    useEffect(() => {
        if (otherPoints?.length === 0) return;
        const sourceCRS = getSourceCRS(selectedProjection);

        proj4.defs('EPSG:3857', targetCRS);
        proj4.defs(`EPSG:${selectedProjection}`, sourceCRS);

        const converted = otherPoints?.map((point) => {
            const [x, y] = proj4(sourceCRS, targetCRS, point.geometry.coordinates);
            return [y, x];
        });

        setConvertedCoord(converted);
    }, [otherPoints, selectedProjection]);

    useEffect(() => {
        if (polylines?.length === 0) return;

        const converted = polylines.map((polyline) => {
            const zone = polyline.zone;
            const sourceCRS = getSourceCRS(zone);
            proj4.defs('EPSG:3857', targetCRS);
            proj4.defs(`EPSG:${zone}`, sourceCRS);
            const convertedCoords = polyline.geometry.coordinates.map((coord) => {
                const [x, y] = proj4(sourceCRS, targetCRS, coord);
                return [y, x];
            });

            return {
                coordinates: convertedCoords,
                project: polyline.projet,
                id: polyline?.id
            };
        });

        setPolylineData(converted);
    }, [polylines]);

    useEffect(() => {
        if (polygons?.length === 0) return;

        const convert = polygons.map((polygon) => {
            const zone = polygon.zone;
            const sourceCRS = getSourceCRS(zone);
            proj4.defs('EPSG:3857', targetCRS);
            proj4.defs(`EPSG:${zone}`, sourceCRS);
            const convertedCoords = polygon.geometry.coordinates.flat();

            return {
                coordinates: convertedCoords.map((coord) => {
                    const [x, y] = proj4(sourceCRS, targetCRS, coord);
                    return [y, x];
                }),
                project: polygon.projet,
                id: polygon.id
            };
        });

        setPolygonData(convert);
    }, [polygons]);

    const handleSearch = () => {
        setInitialZoom(19);
        let foundFeature = null;

        const foundPolyline = polylines.find(
            (polyline) =>
                polyline.projet.numero === searchQuery || polyline.projet.reference_fonciere === searchQuery
        );

        if (foundPolyline) {
            foundFeature = foundPolyline;
        }

        if (!foundFeature) {
            const foundPolygon = polygons.find(
                (polygon) =>
                    polygon.projet.numero === searchQuery || polygon.projet.reference_fonciere === searchQuery
            );

            if (foundPolygon) {
                foundFeature = foundPolygon;
            }
        }

        if (foundFeature) {
            const zone = foundFeature.zone;
            const sourceCRS = getSourceCRS(zone);
            let converted = [];
            if (foundFeature.geometry.type === 'LineString') {
                converted = foundFeature.geometry.coordinates.map((coord) => {
                    const [x, y] = proj4(sourceCRS, targetCRS, coord);
                    return [y, x];
                });
                setConvertedPolylines([converted]);
                setSelectedPolyline(foundFeature);
            } else if (foundFeature.geometry.type === 'Polygon') {
                converted = foundFeature.geometry.coordinates.map((coord, index) => {
                    const [x, y] = proj4(sourceCRS, targetCRS, coord[0]);
                    return [y, x];
                });
                setConvertedPolygons([converted]);
                setSelectedPolygon(foundFeature);
            }

            mapRef.current.flyTo(converted[0], 18);
        } else {
            setConvertedPolylines([]);
            setSelectedPolyline(null);
        }
    };

    const getSourceCRS = (zone) => {
        switch (zone) {
            case '26191':
                return '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
            case '26192':
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
            case '26194':
                return '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.999616304 +x_0=1200000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
            case '26195':
                return '+proj=lcc +lat_1=22.5 +lat_0=22.5 +lon_0=-5.4 +k_0=0.999616437 +x_0=1500000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
            default:
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }
    };

    const Map = () => {
        const map = useMap();
        mapRef.current = map;

        return null;
    };

    const handlePointSubmit = (varia) => {
        if (varia.X && varia.Y && varia.name && varia.nature) {
            PointServices.addPoint({
                name: varia.name,
                nature: varia.nature,
                zone: selectedProjection,
                X: varia.X,
                Y: varia.Y,
                createdBy: varia.igt,
                reference: varia.reference,
                note: varia.note,
            })
                .then((response) => {
                    console.log('Point added successfully:', response?.data.message);
                    handleSnackbarOpen('Point added successfully', 'success');
                    fetchPoints();
                    setAddDialogOpen(false);

                    const sourceCRS = getSourceCRS(selectedProjection);
                    const [newLng, newLat] = proj4(sourceCRS, targetCRS, [parseFloat(varia.X), parseFloat(varia.Y)]);

                    mapRef.current.flyTo([newLat, newLng], 21);
                })
                .catch((error) => {
                    console.error('Error adding point:', error);
                    const errorMessage = error.response && error.response.data && error.response.data.message
                        ? error.response.data.message
                        : 'Error adding point';
                    handleSnackbarOpen(errorMessage, 'error');
                });
        } else {
            handleSnackbarOpen('Please fill in all the form fields', 'error');
        }
    };

    const handlePointUpdate = (varia) => {
        const updatedPoint = {
            name: varia.name,
            nature: varia.nature,
            reference: varia.reference,
            X: varia.X,
            Y: varia.Y,
            createdBy: varia.igt,
            note: varia.note
        };

        PointServices.updatePoint(varia?.id, updatedPoint)
            .then((response) => {
                console.log('Point updated successfully:', response?.data.message);
                handleSnackbarOpen(response?.data.message, 'success');
                fetchPoints();
            })
            .catch((error) => {
                console.error('Error updating point:', error);
                handleSnackbarOpen(error?.response?.data.message, 'error')

            });
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleDeleteDialogOpen = (pointId) => {
        setSelectedPointId(pointId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setSelectedPointId(null);
        setDeleteDialogOpen(false);
    };

    const handleUpdateDialogOpen = (point) => {
        setSelectedPointId(point);
        setUpdateDialogOpen(true);
    };

    const handleUpdateDialogClose = () => {
        setSelectedPointId(null);
        setUpdateDialogOpen(false);
    };


    const handleDeletePoint = () => {
        if (selectedPointId) {
            PointServices.deletePoint(selectedPointId)
                .then((response) => {
                    console.log('Point deleted successfully:', response.data);
                    handleSnackbarOpen(`${response.data.message}`, 'success');
                    handleDeleteDialogClose();
                    fetchPoints();
                })
                .catch((error) => {
                    console.error('Error deleting point:', error);
                    handleSnackbarOpen('Error deleting point', 'error');
                });
        }
    };

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const [X, Y] = proj4('EPSG:3857', `EPSG:${selectedProjection}`, [longitude, latitude]);
                    mapRef.current.flyTo([latitude, longitude], 18);

                    const popupContent = `<div>Ceci est votre emplacement</div>`;
                    L.popup().setLatLng([latitude, longitude]).setContent(popupContent).openOn(mapRef.current);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                    handleSnackbarOpen('Error getting user location', 'error');
                }
            );
        } else {
            handleSnackbarOpen("Geolocation is not supported by your browser", 'error');
        }
    };

    const handleProjectionChange = (event) => {
        setSelectedProjection(event.target.value);
    };

    const goToCoordinates = (x, y) => {
        const sourceCRS = getSourceCRS(selectedProjection);
        const [lng, lat] = proj4(sourceCRS, targetCRS, [x, y]);

        if (mapRef.current) {
            mapRef.current.flyTo([lat, lng], 18); // 16 is an example zoom level
            setPopupPosition([lat, lng]);
            setPopupContent(`X: ${x}, Y: ${y}`);
        }
    };

    const handleGoToCoordinates = () => {
        if (inputX && inputY) {
            goToCoordinates(parseFloat(inputX), parseFloat(inputY));
        } else {
            handleSnackbarOpen('Please enter valid coordinates', 'error');
        }
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box>
                <Grid container spacing={2} mb={2} mt={2}>
                    <Grid item xs={12} className='d-flex'>
                        <TextField
                            label="Recherche par numéro de projet ou référence"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="standard"
                            fullWidth
                            sx={{
                                marginRight: '1rem'
                            }}
                        />
                        <MDButton
                            variant="gradient"
                            color="primary"
                            onClick={handleSearch}
                        >
                            Afficher
                        </MDButton>
                    </Grid>
                </Grid>
            </Box>
            <>
                <Grid container spacing={2} mb={2} mt={2}>
                    <Grid item xs={12} sm={3}>
                        {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des points de rattachement")) && (
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    color: '#fff !important',
                                    marginBottom: '12px',
                                }}
                                onClick={() => setAddDialogOpen(true)}
                            >
                                <AddLocationAlt />&nbsp;
                                Ajouter Un point
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des points de rattachement")) && (
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    color: '#fff !important',
                                    marginBottom: '12px',
                                }}
                                component={Link}
                                to={'/projects/survey/import/points'}
                            >
                                <UploadFile />&nbsp;
                                Importer des points
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                                color: '#fff !important',
                                marginBottom: '12px',
                            }}
                            component={Link}
                            to={'/points/duplicated'}
                        >
                            <WrongLocation />&nbsp;
                            Points dupliqués
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                                color: '#fff !important',
                                marginBottom: '12px',
                            }}
                            onClick={handleGetCurrentLocation}
                        >
                            <MyLocation />&nbsp;
                            Obtenir ma position
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                        <TextField
                            select
                            label='Sélectionner la projection des points'
                            id="projection-select"
                            name="selectedProjection"
                            value={selectedProjection}
                            fullWidth
                            onChange={handleProjectionChange}
                        >
                            <MenuItem value="26191">Zone 1: Merchich / zone Nord</MenuItem>
                            <MenuItem value="26192">Zone 2: Merchich / zone Sud </MenuItem>
                            <MenuItem value="26194">Zone 3: Merchich / sahara Nord</MenuItem>
                            <MenuItem value="26195">Zone 4: Merchich / sahara Nord</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} sm={5}>
                        <TextField
                            label="X Coordinate"
                            value={inputX}
                            onChange={(e) => setInputX(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={5}>
                        <TextField
                            label="Y Coordinate"
                            value={inputY}
                            onChange={(e) => setInputY(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <MDButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleGoToCoordinates}
                        >
                            Go to
                        </MDButton>
                    </Grid>
                </Grid>
                <MapContainer
                    center={[30.39341198110206, -9.565342191279383]}
                    zoom={initialZoom}
                    style={{ height: '80vh', width: '100%' }}
                >
                    <Map />
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="Google Maps">
                            <TileLayer
                                url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                                maxZoom={21}
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                attribution='<a href="https://www.google.com/maps">Google Maps</a>'
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="OpenStreetMap">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                maxZoom={21}
                                attribution='<a href="https://www.openstreetmap.org">OpenStreetMap</a>'
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.Overlay checked name="Rattachements">
                            <MarkerClusterGroup>
                                {convertedCoordinates.map((coord, index) => (
                                    <Marker key={index} position={coord} icon={markerIcon}>
                                        <Popup>
                                            <div>Id: {points[index]?.id}</div>
                                            <div>Name: {points[index]?.name}</div>
                                            <div>Nature: {points[index]?.nature}</div>
                                            <div>Reference: {points[index]?.reference}</div>
                                            <div>X: {points[index]?.geometry?.coordinates[0]}</div>
                                            <div>Y: {points[index]?.geometry?.coordinates[1]}</div>
                                            <div>Observation: {points[index]?.note || '-'}</div>
                                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les points de rattachement")) && (
                                                <MDButton
                                                    variant="contained"
                                                    color="error"
                                                    size='small'
                                                    className='mt-2'
                                                    onClick={() => handleDeleteDialogOpen(points[index].id)}
                                                >
                                                    Supprimer
                                                </MDButton>
                                            )}
                                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les points de rattachement")) && (
                                                <MDButton
                                                    variant="contained"
                                                    color="info"
                                                    size='small'
                                                    className='mt-2 ms-2'
                                                    onClick={() => handleUpdateDialogOpen(points[index])}
                                                >
                                                    Modifier
                                                </MDButton>
                                            )}
                                        </Popup>
                                    </Marker>
                                ))}
                            </MarkerClusterGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Polylines">
                            {polylineData.length > 0 && (
                                <LayerGroup>
                                    {polylineData.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <LeafletPolyline positions={data.coordinates} pathOptions={{ color: 'red' }}>
                                                <Popup>
                                                    <div>
                                                        <strong>Projet:</strong> {data?.project?.numero}
                                                    </div>
                                                    <div>
                                                        <strong>Reference Fonciere:</strong> {data?.project?.reference_fonciere}
                                                    </div>
                                                    <div>
                                                        <strong>Nature:</strong> {data?.project?.nature?.map((item, index) => (
                                                            <span key={index}>
                                                                {item?.name}
                                                                {index < selectedPolyline?.projet?.nature?.length - 1 && ', '}
                                                            </span>
                                                        )) || '-'}
                                                    </div>
                                                    <Box mt={2}>
                                                        <MDButton size='small' color='error'
                                                            onClick={() => handleDeletePolyline(data?.id)}
                                                        >
                                                            Suprimmer
                                                        </MDButton>
                                                    </Box>
                                                </Popup>
                                            </LeafletPolyline>
                                        </React.Fragment>
                                    ))}
                                </LayerGroup>
                            )}
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Polygons">
                            {polygonData.length > 0 && (
                                <LayerGroup>
                                    {polygonData.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <LeafletPolygon positions={data.coordinates}>
                                                <Popup>
                                                    <div>
                                                        <strong>Id:</strong> {data?.id}
                                                    </div>
                                                    <div>
                                                        <strong>Projet:</strong> {data?.project?.numero}
                                                    </div>
                                                    <div>
                                                        <strong>Reference Fonciere:</strong> {data?.project?.reference_fonciere}
                                                    </div>
                                                    <div>
                                                        <strong>Nature:</strong> {data?.project?.nature?.map((item, index) => (
                                                            <span key={index}>
                                                                {item?.name}
                                                                {index < data?.projet?.nature?.length - 1 && ', '}
                                                            </span>
                                                        )) || '-'}
                                                    </div>
                                                    <Box mt={2}>
                                                        <MDButton size='small' color='error'
                                                            onClick={() => handleDeletePolygon(data?.id)}
                                                        >
                                                            Suprimmer
                                                        </MDButton>
                                                    </Box>
                                                </Popup>
                                            </LeafletPolygon>
                                        </React.Fragment>
                                    ))}
                                </LayerGroup>
                            )}
                        </LayersControl.Overlay>
                        <LayersControl.Overlay name="METIC Rattachements">
                        <MarkerClusterGroup>
                                {convertedCoord.map((coord, index) => (
                                    <Marker key={index} position={coord} icon={markerIcon}>
                                        <Popup>
                                            <div>Id: {otherPoints[index]?.id}</div>
                                            <div>Name: {otherPoints[index]?.name}</div>
                                            <div>Nature: {otherPoints[index]?.nature}</div>
                                            <div>Reference: {otherPoints[index]?.reference}</div>
                                            <div>X: {otherPoints[index]?.geometry?.coordinates[0]}</div>
                                            <div>Y: {otherPoints[index]?.geometry?.coordinates[1]}</div>
                                            <div>Observation: {otherPoints[index]?.note || '-'}</div>
                                        </Popup>
                                    </Marker>
                                ))}
                            </MarkerClusterGroup>
                        </LayersControl.Overlay>
                    </LayersControl>
                    {selectedPolyline && (
                        <Popup position={convertedPolylines[0][0]} autoPan={true}>
                            <Box>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        background: '#1A73E8',
                                        color: '#fff',
                                        p: 2,
                                        borderRadius: '8px',
                                        width: '300px',
                                    }}
                                >
                                    <Typography sx={{ color: '#fff', }} variant="h5" fontWeight="bold">
                                        Informations de Projet
                                    </Typography>
                                </Paper>
                                <Box mt={2} p={1}>
                                    <Typography>
                                        <strong>Numero:</strong> {selectedPolyline?.projet?.numero}
                                    </Typography>
                                    <Typography>
                                        <strong>Reference Fonciere:</strong>{' '}
                                        {selectedPolyline?.projet?.reference_fonciere}
                                    </Typography>
                                    <Typography>
                                        <strong>Nature:</strong>{' '}
                                        {selectedPolyline?.projet?.nature?.map((item, index) => (
                                            <span key={index}>
                                                {item?.name}
                                                {index < selectedPolyline?.projet?.nature?.length - 1 && ', '}
                                            </span>
                                        )) || ''}
                                    </Typography>
                                    <Box mt={2}>
                                        <MDButton size='small' color='error'
                                            onClick={() => handleDeletePolyline(selectedPolyline?.id)}
                                        >
                                            Suprimmer
                                        </MDButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Popup>
                    )}
                    {selectedPolygon && (
                        <Popup position={convertedPolygons[0][0]} autoPan={true}>
                            <Box>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        background: '#1A73E8',
                                        color: '#fff',
                                        p: 2,
                                        borderRadius: '8px',
                                        width: '300px',
                                    }}
                                >
                                    <Typography sx={{ color: '#fff', }} variant="h5" fontWeight="bold">
                                        Informations de Projet
                                    </Typography>
                                </Paper>
                                <Box mt={2} p={1}>
                                    <Typography>
                                        <strong>Numero:</strong> {selectedPolygon?.projet?.numero}
                                    </Typography>
                                    <Typography>
                                        <strong>Reference Fonciere:</strong>{' '}
                                        {selectedPolygon?.projet?.reference_fonciere}
                                    </Typography>
                                    <Typography>
                                        <strong>Nature:</strong>{' '}
                                        {selectedPolygon?.projet?.nature?.map((item, index) => (
                                            <span key={index}>
                                                {item?.name}
                                                {index < selectedPolygon?.projet?.nature?.length - 1 && ', '}
                                            </span>
                                        )) || ''}
                                    </Typography>
                                    <Box mt={2}>
                                        <MDButton size='small' color='error'
                                            onClick={() => handleDeletePolyline(selectedPolygon?.id)}
                                        >
                                            Suprimmer
                                        </MDButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Popup>
                    )}
                    {popupPosition && (
                        <Marker position={popupPosition} icon={redMarkerIcon}>
                            <Popup>
                                <div>{popupContent}</div>
                            </Popup>
                        </Marker>
                    )}
                </MapContainer>
            </>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Confirmation de la suppression</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Annuler</Button>
                    <Button onClick={handleDeletePoint} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle className='text-center' bgcolor='#ffc107' style={{ fontSize: '29px' }}>
                    Ajouter un point
                </DialogTitle>
                <DialogContent>
                    <AddPoint handlePointSubmit={handlePointSubmit} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddDialogOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={updateDialogOpen}
                onClose={handleUpdateDialogClose}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle className='text-center' bgcolor='#ffc107' style={{ fontSize: '29px' }}>
                    Modifier point
                </DialogTitle>
                <DialogContent>
                    <UpdatePoint point={selectedPointId} handlePointUpdate={handlePointUpdate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateDialogClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default SurveyMap;
